import React, { useState, useEffect } from 'react';
import { Layout, notification } from 'antd';
import GridFilter from './components/GridFilter';
import { downloadInvoices, getInvoices, getStats } from '@services/invoice';
import { IInvoiceFilter } from '@services/invoice/invoice.model';
import Stats from './components/Stats';
import DocumentsTable from './components/DocumentsTable';
import PenaltiesTable from './components/PenaltiesTable';
import ViewModeButtons from './components/ViewModeButtons';
import { useQuery } from 'react-query';
import { handleAPIError, IFetchQuery } from '@services/base';

const { Content } = Layout;

const Widget: React.FC = () => {
  const [query, setQuery] = useState<IFetchQuery>({ page: 1, page_size: 100 });
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 100,
    showSizeChanger: false,
    position: ['bottomCenter' as 'bottomCenter'],
  });
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<'documents' | 'penalty'>(
    'documents',
  );
  const [filter, setFilter] = useState<IInvoiceFilter>({
    invoice_number: '',
    type: '',
    emission_range: `${new Date().getFullYear()}-01-01,${new Date().getFullYear()}-12-31`,
    competence_range: '',
    carrier_id: '',
    sennder_reference: '',
    customer_reference: '',
    tags: [],
  });
  const [stats, setStats] = useState<any>();

  const onCsvDownload = async () => {
    try {
      const response = await downloadInvoices(filter, viewMode);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoices.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      notification.error({
        message: 'Error downloading invoices',
      });
    }
  };

  const onFilter = (values: IInvoiceFilter) => {
    setQuery({ ...query, page: 1, page_size: 100 });
    setPagination({ ...pagination, current: 1 });
    setFilter(values);
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const stats = await getStats(filter);
        setStats(stats);
      } catch (error) {
        notification.error({
          message: 'Error fetching stats',
        });
      }
    };

    fetchStats();
  }, [filter]);

  const { data: invoicesData, isFetching } = useQuery(
    ['invoices', filter, query],
    () => getInvoices({ ...query, ...filter }),
    {
      keepPreviousData: true,
      onSuccess: res => {
        setPagination(prev => ({
          ...prev,
          total: res?.total_values || 0,
        }));
        setSelectedRows([]);
      },
      onError: handleAPIError,
    },
  );

  return (
    <>
      <Stats isLoading={isFetching} data={stats} />
      <Content
        className={'bg-white'}
        style={{ borderRadius: '15px', overflow: 'hidden' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <GridFilter
            data={filter}
            onFilter={onFilter}
            onCsvDownload={onCsvDownload}
            invoices={invoicesData?.invoices || []}
          />
          <ViewModeButtons viewMode={viewMode} setViewMode={setViewMode} />
        </div>
        {viewMode === 'documents' ? (
          <DocumentsTable
            query={query}
            setQuery={setQuery}
            pagination={pagination}
            setPagination={setPagination}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            filter={filter}
            processingIds={processingIds}
            setProcessingIds={setProcessingIds}
            stats={stats}
            setStats={setStats}
          />
        ) : (
          <PenaltiesTable
            query={query}
            setQuery={setQuery}
            pagination={pagination}
            setPagination={setPagination}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            filter={filter}
            processingIds={processingIds}
            setProcessingIds={setProcessingIds}
            stats={stats}
            setStats={setStats}
          />
        )}
      </Content>
    </>
  );
};

export default Widget;
