import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { Table, notification } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import { useQuery } from 'react-query';
import {
  getInvoicesViewPerPenalties,
  getStats,
  undoBillingPerPenalty,
} from '@services/invoice';
import { IInvoicePerPenalty } from '@services/invoice/invoice.model';
import BulkActions from './BulkActions';
import ActionCell from './ActionCell';

interface PenaltiesTableProps {
  query: any;
  setQuery: (query: any) => void;
  pagination: any;
  setPagination: (pagination: any) => void;
  selectedRows: React.Key[];
  setSelectedRows: (rows: React.Key[]) => void;
  processingIds: string[];
  setProcessingIds: (ids: string[]) => void;
  filter: any;
  stats: any;
  setStats: (stats: any) => void;
}

const PenaltiesTable: React.FC<PenaltiesTableProps> = ({
  query,
  setQuery,
  pagination,
  setPagination,
  selectedRows,
  setSelectedRows,
  processingIds,
  setProcessingIds,
  filter,
  stats,
  setStats,
}) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const mappedRows = useMemo(() => {
    return selectedRows.map(id => id.toString().split('-')[1]);
  }, [selectedRows]);
  const [data, setData] = useState<IInvoicePerPenalty[]>([]);
  const processingIntervalRef = useRef<any>(null);
  const [processingTime, setProcessingTime] = useState(0);
  const [processing, setProcessing] = useState(false);
  //const [stats, setStats] = useState<any>(); //eslint-disable-line
  const [isLoading, setLoading] = useState<boolean>(false);
  const columns: ColumnsType<IInvoicePerPenalty> = [
    {
      title: 'Sennder Reference',
      dataIndex: 'sennder_reference',
      key: 'sennder_reference',
      width: 150,
    },
    {
      title: 'Document Number',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => Number(a.invoice_number) - Number(b.invoice_number),
      width: 150,
      render: (text: string, record: IInvoicePerPenalty) => (
        <a href={record.invoice_doc} download>
          {text}
        </a>
      ),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier_name',
      key: 'carrier_name',
      width: 150,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      width: 150,
    },
    {
      title: 'Competence',
      dataIndex: 'competence',
      key: 'competence',
      render: text =>
        `${new Date(text)
          .toLocaleString('default', { month: 'short', year: 'numeric' })
          .toUpperCase()}`,
      width: 150,
    },
    {
      title: 'Emission',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => new Date(text).toLocaleDateString(),
      width: 150,
    },
    {
      title: 'Accounted',
      dataIndex: 'accounted',
      key: 'accounted',
      render: text => (text ? 'Yes' : 'No'),
      width: 100,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      key: 'actions',
      width: 84,
      fixed: 'right',
      render: (data: IInvoicePerPenalty) => {
        return (
          <ActionCell
            data={data}
            isPenaltyView={true}
            processing={processingIds.includes(data._id)}
            onUpdateStatus={updateInvoiceStatus}
            undoBillingFn={handleUndoBilling} // Pass undoBillingPerPenalty function
            refetch={refetch} // Pass refetch function
          />
        );
      },
    },
  ];

  const updateInvoiceStatus = (id: string) => {
    setData(prevData =>
      prevData.map(invoice =>
        invoice._id === id ? { ...invoice, accounted: true } : invoice,
      ),
    );
  };

  const handleUndoBilling = async (penaltiesIds: string[]) => {
    try {
      setLoading(true);
      const res = await undoBillingPerPenalty(penaltiesIds);
      return res;
    } catch (error: any) {
      notification.error({
        description: error.message,
        message: 'Cannot create RDA',
      });
    } finally {
      setLoading(false);
    }
  };

  const { isFetching, refetch } = useQuery(
    ['invoices', filter, query],
    () => getInvoicesViewPerPenalties({ ...query, ...filter }),
    {
      onSuccess: async (res: { data: IInvoicePerPenalty[]; total: number }) => {
        setData(res.data);
        const stats = await getStats();
        setStats(stats);
        setPagination({ ...pagination, total: res.total });
      },
      onError: (error: { message: string }) => {
        notification.error({
          message: 'Error fetching invoices',
          description: error.message,
        });
      },
    },
  );

  const onSelectChange = (rows: React.Key[]) => {
    if (processingIds.length)
      return notification.error({
        message: 'Cannot select penalties while processing',
      });
    setSelectedRows(rows);
  };

  const onChangeTable: TableProps<IInvoicePerPenalty>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    setQuery({ ...query, page: pagination.current ?? 1 });
  };

  const startProcessing = useCallback((ids: string[]) => {
    setProcessingIds(ids);
    if (processingIntervalRef.current !== null) return;

    processingIntervalRef.current = setInterval(() => {
      setProcessingTime(prevCount => prevCount + 1);
    }, 1000);
  }, []); //eslint-disable-line

  const endProcessing = useCallback(() => {
    setProcessingIds([]);
    clearInterval(processingIntervalRef.current);
    processingIntervalRef.current = null;
    setProcessingTime(0);
  }, []); //eslint-disable-line

  useEffect(() => {
    setProcessing(processingIds.length > 0);
  }, [processingIds]);

  return (
    <div ref={refContainer} style={{ height: '80%' }}>
      <Table
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: onSelectChange,
        }}
        loading={isFetching || isLoading}
        onChange={onChangeTable}
        columns={columns}
        pagination={pagination}
        rowKey="row_key"
        dataSource={data}
        scroll={
          refContainer?.current
            ? {
                y: refContainer.current.clientHeight - 56 - 56,
                x: refContainer.current.clientWidth,
              }
            : undefined
        }
      />
      {selectedRows.length > 0 && (
        <BulkActions
          ids={mappedRows}
          processing={processing}
          undoBillingFn={undoBillingPerPenalty}
          processingTime={processingTime}
          onProcessingStart={startProcessing}
          onProcessingEnd={endProcessing}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default PenaltiesTable;
