import React, { useState } from 'react';
import { Menu, Spin } from 'antd';
import {
  ImportOutlined,
  LinkOutlined,
  LogoutOutlined,
  MenuOutlined,
  PlusOutlined,
  DotChartOutlined,
  FileOutlined,
} from '@ant-design/icons';
import useAuth from '@hooks/use-auth';
import useModal, { IModalPayload } from '@hooks/use-modal';

const AppMenu: React.FC = () => {
  let { data, logout } = useAuth();
  const [, importCSV] = useModal<IModalPayload<any>>('import-csv');
  const [, importNoShowCsv] = useModal<IModalPayload<any>>('import-noshow-csv');
  const [, uploadInternalFiles] = useModal<
    IModalPayload<{ sennderRef: string | null; competence?: string | null }>
  >('upload-internal-files');

  //const [, importNoShowCSV] = useModal<IModalPayload<any>>('import-no-show');

  const [, addUser] = useModal<IModalPayload<any>>('add-user');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, _] = useState<boolean>(false);

  const { flatPermissions = {} } = data || {};
  let roleName = 'User';
  if (flatPermissions['edit']) {
    roleName = 'Admin';
  } else if (flatPermissions['view']) {
    roleName = 'Viewer';
  } else if (
    data?.permissions.length === 1 &&
    data?.permissions[0] === 'financial'
  ) {
    roleName = 'Finance';
  }

  return (
    <Spin spinning={isLoading}>
      <Menu
        theme="dark"
        mode="horizontal"
        triggerSubMenuAction={'click'}
        items={[
          {
            key: 'expand',
            icon: <MenuOutlined />,
            children: [
              {
                key: 'username',
                label: `${data?.displayName || ''}`,
              },
              {
                key: 'role',
                label: `Role: ${roleName}`,
              },
              {
                key: 'import-csv',
                label: 'Import CSV',
                icon: <ImportOutlined />,
                permissions: ['edit'],
                onClick: () => {
                  importCSV({ isOpen: true, payload: null });
                },
              },
              {
                key: 'upload-files',
                label: 'Upload files',
                icon: <FileOutlined />,
                permissions: ['edit'],
                onClick: () => {
                  uploadInternalFiles({
                    isOpen: true,
                    payload: { sennderRef: null, competence: null },
                  });
                },
              },
              {
                key: 'import-noshow-csv',
                label: 'Import NO SHOW CSV',
                icon: <ImportOutlined />,
                permissions: ['edit'],
                onClick: () => {
                  importNoShowCsv({ isOpen: true, payload: null });
                },
              },
              /*
              {
                key: 'import-no-show',
                label: 'Import No Show',
                icon: <ImportOutlined />,
                permissions: ['edit'],
                onClick: () => {
                  importNoShowCSV({ isOpen: true, payload: null });
                },
              },
              */
              {
                key: 'financial',
                label: 'Financial Section',
                icon: <LinkOutlined />,
                onClick: () => {
                  window.open('/financial');
                },
              },
              {
                key: 'analytics',
                label: 'View Analytics',
                icon: <DotChartOutlined />,
                onClick: () => {
                  window.open('/analytics');
                },
              },

              {
                key: 'add-user',
                label: 'Add User',
                icon: <PlusOutlined />,
                permissions: ['superuser'],
                onClick: () => {
                  addUser({ isOpen: true, payload: null });
                },
              },

              {
                key: 'documentation',
                label: 'Documentation',
                icon: <LinkOutlined />,
                onClick: () => {
                  window.open(
                    'https://sennder-italia.github.io/open-positions/?src=Pv2-Doc',
                  );
                },
              },
              {
                key: 'logout',
                label: 'Logout',
                icon: <LogoutOutlined />,
                onClick: async () => {
                  await logout();
                },
              },
            ].filter(item => {
              const { permissions = [] } = item;
              return permissions.length
                ? permissions.some(role => {
                    return flatPermissions[role];
                  })
                : true;
            }),
          },
        ]}
      />
    </Spin>
  );
};

export default AppMenu;
