import { Button, Space, Tooltip, Typography, notification } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import useAuth from '@hooks/use-auth';
import { useMutation } from 'react-query';
import { IInvoice } from '@services/invoice/invoice.model';
import { IResponse } from '@services/base';

const { Text } = Typography;

const BulkActions = ({
  ids,
  onProcessingStart,
  onProcessingEnd,
  processing,
  undoBillingFn,
  processingTime,
  refetch,
}: {
  ids: string[];
  onProcessingStart: any;
  onProcessingEnd: any;
  processing: boolean;
  undoBillingFn: (
    invoice_number: string[],
  ) => Promise<IResponse & { invoice: IInvoice }>;
  processingTime: number;
  refetch: () => void;
}) => {
  const { data } = useAuth();
  const { flatPermissions = {} } = data || {};

  const mutation = useMutation((ids: string[]) => undoBillingFn(ids), {
    onSuccess: (data: any) => {
      data.forEach((result: any) => {
        if (result.success) {
          notification.success({
            message: `(${result.id}) RDA created successfully`,
          });
        } else
          notification.error({
            description: result.error,
            message: `(${result.id}) Cannot create RDA`,
          });
      });
      onProcessingEnd();
      refetch();
    },
    onError: error => {
      notification.error({
        message: 'Failed to create RDA',
        description: error as string,
      });
    },
  });

  const actions = [
    {
      colorType: 'default',
      permissions: ['edit'],
      icon: <FileTextOutlined />,
      label: 'Create RDA',
      onClick: () => {
        onProcessingStart(ids);
        mutation.mutate(ids);
      },
    },
  ];

  const getActionItem = (item: any) => {
    const { permissions = [] } = item;
    const hasPermissions = permissions.length
      ? permissions.some((role: any) => flatPermissions[role])
      : true;

    if (hasPermissions) {
      return (
        <Tooltip key={item.label} title={item.label}>
          <Button
            onClick={item.onClick}
            type={item.colorType}
            disabled={processing}
          >
            {item.icon}
          </Button>
        </Tooltip>
      );
    }
    return null;
  };

  const actionsItems = actions.map(item => getActionItem(item));

  return (
    <div
      className={classNames('bulk-actions', {
        'bulk-actions--active': true,
      })}
    >
      <Space>
        <Text>
          {processing ? (
            <Text>
              Processing <strong className="mr-1">{ids.length}</strong> invoices
            </Text>
          ) : (
            <Text>
              <strong className="mr-1">{ids.length}</strong> selected items
            </Text>
          )}
        </Text>
        {actionsItems}
      </Space>
    </div>
  );
};

export default BulkActions;
