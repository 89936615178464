import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Table, notification } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { getInvoices, getStats, undoBilling } from '@services/invoice';
import { IInvoice } from '@services/invoice/invoice.model';
import ActionCell from './ActionCell';
import BulkActions from './BulkActions';

interface DocumentsTableProps {
  query: any;
  setQuery: (query: any) => void;
  pagination: any;
  setPagination: (pagination: any) => void;
  selectedRows: React.Key[];
  setSelectedRows: (rows: React.Key[]) => void;
  processingIds: string[];
  setProcessingIds: (ids: string[]) => void;
  filter: any;
  stats: any;
  setStats: (stats: any) => void;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  query,
  setQuery,
  pagination,
  setPagination,
  selectedRows,
  setSelectedRows,
  processingIds,
  setProcessingIds,
  filter,
  stats,
  setStats,
}) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const mappedRows = useMemo(
    () => selectedRows.map(id => id as string),
    [selectedRows],
  );
  const [data, setData] = useState<IInvoice[]>([]);
  //const [stats, setStats] = useState<any>(); //eslint-disable-line
  const processingIntervalRef = useRef<any>(null);
  const [processingTime, setProcessingTime] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const columns: ColumnsType<IInvoice> = [
    {
      title: 'Document Number',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => Number(a.invoice_number) - Number(b.invoice_number),
      width: 150,
      render: (text: string, record: IInvoice) => (
        <a href={record.invoice_doc} download>
          {text}
        </a>
      ),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      render: (text: { carrier_name: string }) => <p>{text.carrier_name}</p>,
      width: 150,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      width: 150,
    },
    {
      title: 'Competence',
      dataIndex: 'competence_range',
      key: 'competence_range',
      render: text =>
        `${new Date(text.start)
          .toLocaleString('default', { month: 'short', year: 'numeric' })
          .toUpperCase()} - ${new Date(text.end)
          .toLocaleString('default', { month: 'short', year: 'numeric' })
          .toUpperCase()}`,
      width: 150,
    },
    {
      title: 'Emission',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => new Date(text).toLocaleDateString(),
      width: 150,
    },
    {
      title: 'Accounted',
      dataIndex: 'accounted',
      key: 'accounted',
      render: text => (text ? 'Yes' : 'No'),
      width: 100,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      key: 'actions',
      width: 84,
      fixed: 'right',
      render: (data: IInvoice) => {
        return (
          <ActionCell
            isPenaltyView={false}
            data={data}
            processing={processingIds.includes(data._id)}
            undoBillingFn={handleUndoBilling}
            onUpdateStatus={updateInvoiceStatus}
            refetch={refetch}
          />
        );
      },
    },
  ];

  const handleUndoBilling = async (invoiceNumbers: string[]) => {
    try {
      setLoading(true);
      const res = await undoBilling(invoiceNumbers);
      return res;
    } catch (error: any) {
      notification.error({
        description: error.message,
        message: 'Cannot create RDA',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateInvoiceStatus = (id: string) => {
    setData(prevData =>
      prevData.map(invoice =>
        invoice._id === id ? { ...invoice, accounted: true } : invoice,
      ),
    );
  };

  const { isFetching, refetch } = useQuery(
    ['invoices', filter, query],
    () => getInvoices({ ...query, ...filter }),
    {
      onSuccess: async (res: { data: IInvoice[]; total: number }) => {
        setData(res.data);
        setPagination({ ...pagination, total: res.total });
        const stats = await getStats();
        setStats(stats);
      },
      onError: (error: { message: string }) => {
        notification.error({
          message: 'Error fetching invoices',
          description: error.message,
        });
      },
    },
  );

  const onSelectChange = (rows: React.Key[]) => {
    setSelectedRows(rows);
  };

  const onChangeTable: TableProps<IInvoice>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    setQuery({ ...query, page: pagination.current ?? 1 });
  };

  const startProcessing = useCallback((ids: string[]) => {
    setProcessingIds(ids);
    if (processingIntervalRef.current !== null) return;

    processingIntervalRef.current = setInterval(() => {
      setProcessingTime(prevCount => prevCount + 1);
    }, 1000);
  }, []); //eslint-disable-line

  const endProcessing = useCallback(() => {
    setProcessingIds([]);
    clearInterval(processingIntervalRef.current);
    processingIntervalRef.current = null;
    setProcessingTime(0);
  }, []); //eslint-disable-line

  useEffect(() => {
    setProcessing(processingIds.length > 0);
  }, [processingIds]); //eslint-disable-line

  return (
    <div ref={refContainer} style={{ height: '80%' }}>
      <Table
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: onSelectChange,
        }}
        loading={isFetching || isLoading}
        onChange={onChangeTable}
        columns={columns}
        pagination={pagination}
        rowKey="invoice_number"
        dataSource={data}
        scroll={
          refContainer?.current
            ? {
                y: refContainer.current.clientHeight - 56 - 56,
                x: refContainer.current.clientWidth,
              }
            : undefined
        }
      />
      {selectedRows.length > 0 && (
        <BulkActions
          ids={mappedRows}
          undoBillingFn={undoBilling}
          processing={processing}
          processingTime={processingTime}
          onProcessingStart={startProcessing}
          onProcessingEnd={endProcessing}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default DocumentsTable;
