import React from 'react';
import { Button, Dropdown, Menu, notification } from 'antd';
import {
  CheckOutlined,
  FileTextOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { updateInvoice } from '@services/invoice';
import { useMutation } from 'react-query';

const ActionCell = ({
  data,
  processing,
  onUpdateStatus,
  refetch,
  undoBillingFn,
  isPenaltyView,
}: {
  data: any;
  processing: boolean;
  onUpdateStatus: (id: string) => void;
  refetch(): void;
  isPenaltyView: boolean;
  undoBillingFn: (invoice_number: string[]) => Promise<any>;
}) => {
  const actions = [];

  if (data.type === 'RDP') {
    actions.push({
      label: 'Create RDA',
      icon: <FileTextOutlined />,
      onClick: async () => {
        try {
          await mutateAsync([isPenaltyView ? data._id : data.invoice_number]);
        } catch (error) {
          notification.error({
            message: 'Failed to create RDA',
            description: error as string,
          });
        }
      },
    });
  }

  const { mutateAsync } = useMutation(undoBillingFn, {
    onSuccess: res => {
      res.forEach((result: any) => {
        if (result.success) {
          notification.success({
            message: `(${result.id}) RDA created successfully`,
          });
        } else
          notification.error({
            description: result.error,
            message: `(${result.id}) Cannot create RDA`,
          });
      });

      refetch();
    },
    onError: error => {
      notification.error({
        message: 'Failed to create RDA',
        description: error as string,
      });
    },
  });

  if (!data.accounted && !isPenaltyView) {
    actions.push({
      label: 'Account',
      icon: <CheckOutlined />,
      onClick: async () => {
        await updateInvoice(data._id);
        notification.success({ message: 'Invoice Accounted Successfully' });
        onUpdateStatus(data._id);
      },
    });
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          {actions.map(action => (
            <Menu.Item
              key={action.label}
              onClick={action.onClick}
              disabled={processing}
            >
              {action.icon || null} {action.label}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button type="text" icon={<MoreOutlined />} />
    </Dropdown>
  );
};

export default ActionCell;
